import React from 'react'

export default function Title ({
  children,
  className,
  size = 6,
  display = false, // use display-1 to display-6 instead of fs-1 to fs-6
  h = false, // use h1 to h6 instead of div
  center = false
}) {
  if (!children) {
    return null
  }

  const Tag = `h${size > 6 ? 6 : (size < 1 ? 1 : size)}`
  const classes = `${display ? 'display' : 'fs'}-${size} fw-semibold ${center ? 'text-center' : ''} ${className}`

  return (
    h ? (
        React.createElement(Tag, {
          className: `m-0 ${classes}`
        }, children)
      )
      : (
        <div
          className={classes}
        >
          {children}
        </div>
      )
  )
}
