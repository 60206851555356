export default function Card ({
  children,
  gap = 4,
  center = false,
  className
}) {
  return (
    <div className={`d-flex flex-column gap-${gap} ${center ? 'align-items-center text-center' : ''} ${className}`}>
      {children}
    </div>
  )
}
