import * as NextImage from 'next/image'

export default function Image ({
  src,
  height = 250,
  width = '100%',
  contain = false,
  className = ''
}) {
  return (
    <div
      className={`position-relative ${className}`}
      style={{ height, width }}
    >
      <NextImage
        src={src ?? '/img/placeholder.png'}
        alt={`...`}
        className={`w-100 h-100 br-2`}
        layout="fill"
        objectFit={contain ? 'contain' : 'cover'}
      />
    </div>
  )
}
