import { useState } from 'react'
import Image from 'next/image'
import dynamic from "next/dynamic"
import styles from '../styles/components/VideoTestimonials.module.css'
const MuxVideoProvider = dynamic(() => import('./MuxVideoProvider'))

export const MuxVideo = ({ id, width, playVideo, isPlaying, className, thumbnailStartTime = 0 }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <div
      className={`position-relative ${className} ${styles.videoContainer}`}
      onClick={() => {
        if (isLoaded) {
          playVideo(id)
        } else {
          setIsLoading(true)
        }
      }}
    >
      {(isLoading || isLoaded) && (
        <MuxVideoProvider
          key={id}
          id={id}
          isLoaded={isLoaded}
          isPlaying={isPlaying}
          onLoadedData={() => {
            setIsLoaded(true)
            setIsLoading(false)
            playVideo(id)
          }}
        />
      )}

      { !isLoaded && (
        <>
          <Image
            src={`https://image.mux.com/${id}/thumbnail.webp?height=360&time=${thumbnailStartTime}`}
            alt='Ivy Flip Video Testimonial'
            height={360}
            width={width}
            priority
            layout='fixed'
            objectFit='cover'
            className='br-3'
          />
          {isLoading && (
            <Skeleton />
          )}
        </>
      ) }
      { !isLoading && !isPlaying && (
        <PlayButton />
      ) }
    </div>
  )
}

const PlayButton = () => {
  return (
    <div style={{
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
      borderRadius: '1rem',
      paddingTop: '100px'
    }} >
      <div className={`${styles.playButton} text-white bg-success`}>
        {'\u25B6'}
      </div>
    </div>
  )
}

const Skeleton = () => {
  return (
    <div style={{
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      borderRadius: '1rem'
    }}>
      <div
        className={styles.skeleton}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%'
        }}
      />
    </div>
  )
}
