export default function Text ({
  children,
  size = 6,
  center = false,
  accent = false,
  className
}) {
  if (!children) {
    return null
  }

  return (
    <div className={`${accent ? '' : 'opacity-50'} fs-${size} ${className} ${center ? 'text-center' : ''}`}>
      {children}
    </div>
  )
}
